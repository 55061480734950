.accountSecurity {
    display: flex;
    margin-top: 30px;
    width: 100%;
    max-width: 746px;
    gap: 20px;
    margin-left: -20px;
  }
  
  .securityColumn {
    width: 43%;
    color: #475467;
    font: 400 16px Inter, sans-serif;
  }
  
  .sectionTitle, .subsectionTitle {
    font-weight: 600;
  }
  
  .subsectionTitle {
    margin-top: 38px;
  }
  
  .securityItem {
    margin-top: 25px;
  }
  .securityitems{
    margin-left: 20px !important;
  }
  
  .actionColumn {
    width: 57%;
    color: #949fae;
    font: 400 16px Inter, sans-serif;
    margin-top: 49px;
  }
  .actionColumns{
    text-align: left !important;
    margin-top: 130px;
    margin-left: 10px;
  }
  
  .actionItem {
    margin-top: 25px;
    text-align: right;
  }
  
  .actionItem:first-child {
    padding-left: 62px;
  }
  
  .actionItem:nth-child(2) {
    align-self: flex-end;
  }
  
  .actionItem:nth-child(3) {
    margin-top: 141px;
  }
  
  .actionItem:last-child {
    align-self: flex-end;
  }
  /* AccountSecurity.module.css */

.accountSecurity {
  padding: 20px;
}

.securityColumn {
  margin-bottom: 20px;
}

.sectionTitle {
  font-size: 24px;
  margin-bottom: 10px;
}

.subsectionTitle {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.securityRow {
  width: 100vw;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  margin-bottom: 10px;
  gap: 100px;
}

.securityLabel {
  font-weight: bold;
  flex: 1;
}

.securityAction {
  flex: 2;
  color: #555;
}

.securityItem {
  margin: 5px 0;
}

.actionColumns {
  margin-top: 20px;
}

/* Add additional styles as needed */

  
  @media (max-width: 991px) {
    .accountSecurity {
      flex-direction: column;
      margin-top: 40px;
      margin-left: -20px;
    }
    
    .securityColumn, .actionColumn {
      width: 100%;
    }
    .actionColumn {
      width: 58vw;
      margin-top: -310px;
      margin-left: 110px;
      font-size: 15px;
    }
    .actionColumns{
      margin-top: 60%;
    }
    .subsectionTitle {
      margin-top: 40px;
    }
    
    .actionItem:first-child {
      padding-left: 20px;
    }
    
    .actionItem:nth-child(3) {
      margin: 40px 2px 0 0;
    }
    
    .actionItem:last-child {
      margin-right: 2px;
    }
  }
  @media(max-width: 480px){
    .accountSecurity{
      display: none;
    }
  }