.filterWrapper {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .deviceFilter,
  .modelFilter {
    display: flex;
    align-items: center;
  }
  .modelFilter{margin-right: 80%;}
  .select {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    background-color: #fff;
    font-size: 14px;
    color: #344054;
  }
  
  .clearButton {
    margin-left: 10px;
    background: none;
    border: none;
    color: #585757;
    cursor: pointer;
  }
  .visuallyHidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  
  @media (max-width: 991px) {
    .filterWrapper {
      flex-direction: column;
      align-items: flex-start;
    }
  }