.loginPage {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .loginContent {
    display: flex;
    min-height: 960px;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  
  .loginSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    flex-basis: 0%;
    min-width: 240px;
  }
  
  .header {
    display: flex;
    min-height: 96px;
    width: 100%;
    align-items: flex-start;
    padding: 38px 32px;
  }
  
  .logo {
    aspect-ratio: 4.85;
    object-fit: contain;
    width: 102px;
  }
  
  .decorativeElement {
    background-color: rgba(150, 120, 225, 1);
    width: 12px;
    height: 12px;
    margin-left: 12px;
  }
  
  .mainContent {
    display: flex;
    margin-top: 124px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0 32px;
  }
  
  .formContainer {
    width: 360px;
    max-width: 100%;
  }
  
  .title {
    color: #101828;
    font-size: 36px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -0.72px;
  }
  
  .supportingText {
    color: #475467;
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;
  }
  
  .loginForm {
    margin-top: 32px;
  }
  
  .inputGroup {
    margin-bottom: 20px;
  }
  
  .label {
    color: #344054;
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 6px;
  }
  
  .input {
    width: 100%;
    padding: 10px 14px;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    font-size: 16px;
    color: #667085;
  }
  
  .formOptions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .rememberMe {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #344054;
    font-size: 14px;
  }
  
  .forgotPassword {
    color: #6941c6;
    font-size: 14px;
    font-weight: 600;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .submitButton {
    width: 26vw;
    padding: 10px 18px;
    background-color: #7f56d9;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px;
  }
  .socialLogin {
    margin-top: 16px;
  }
  
  .googleLogin {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 10px 16px;
    margin-left: 29%;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    background-color: #fff;
    color: #344054;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
  .socialSign {
    margin-top: 16px;
  }
  
  .googleIcon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
  
  .signupPrompt {
    margin-top: 32px;
    text-align: center;
    font-size: 14px;
  }
  
  .signupLink {
    color: #6941c6;
    font-weight: 600;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 4px;
  }
  
  .footer {
    margin-top: 124px;
    min-height: 96px;
    width: 100%;
    color: #475467;
    padding: 38px 32px;
    font-size: 14px;
  }
  
  .sideImage {
    aspect-ratio: 0.75;
    object-fit: contain;
    width: 100%;
    min-width: 240px;
    flex: 1;
    flex-basis: 0%;
  }


/* Modal Overlay */
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
/* Modal Content */
.modalContent {
  position: relative;
  width: 20%;
  margin-top: 15%;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  outline: none;
  text-align: center;
  margin-left: 40%;
}

/* Button Styles */
.buttonVerify {
  padding: 10px 20px;
  border: 1px solid gray;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  background-color: #6941c6;
  color: white;
  margin-right: 10px;
  margin-left: 20px;
  margin-top: 20px;
}

.buttonClose {
  padding: 10px 20px;
  border: 1px solid gray;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  color: black;
}
.error {
  color: red;
  margin-top: 10px;
}

  @media (max-width: 991px) {
    .loginContent {
      max-width: 100%;
    }
  
    .loginSection {
      max-width: 100%;
    }
  
    .header {
      max-width: 100%;
      padding: 0 20px;
    }
  
    .mainContent {
      max-width: 100%;
      margin-top: 40px;
      padding: 0 20px;
    }
  
    .footer {
      max-width: 100%;
      margin-top: 40px;
      padding: 0 20px;
    }
  
    .sideImage {
      max-width: 100%;
    }
  }