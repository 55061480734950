.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(2.5px); /* Apply blur effect */
  z-index: 1000; /* Ensure it's above other content */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: #fff;
  padding:  35px 31px 31px 31px;
  /* padding: 20px; */
  border-radius: 8px;
  width: 1029px;
  height:685px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modalHeader {
  display: flex;
  justify-content: space-between; /* Space between left and right content */
  align-items: center; /* Align items vertically in the center */
  /* padding: 10px; */
  /* border-bottom: 1px solid #e0e0e0; */
}

.modalTitle {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.modalTime {
  font-size: 14px;
  color: #666;
  margin-left: 10px; /* Space between the title and time */
}

.modalLeft {
  display: flex;
  align-items: center; /* Align the title and time vertically */
}

.modalRight {
  display: flex;
  gap: 10px; /* Space between the two SVGs */
}

.cameraView canvas {
  position: relative;
  border-radius: 24px !important;
  /* padding: 20px; */
}

.deviceImage{
  position: relative;
  /* width: 960px; */
height: 481px;
flex-shrink: 0;
border-radius: 12px !important;

}


.cameraOverlay {
  position: relative;
  top: 20px;
  gap:15px;
  display: flex;
  align-items: center;
}

.iconContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.sdIcon, .cloudIcon {
  width: 48px;
  height: 24px;
  font-size: 12px;
  color: #000000;
  padding: 3px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.ic {
  text-align: center;
}

.iconOn {
  background-color: #ffffff;
  border: 1px solid #000000;
}

.iconOff {
  background-color: #d9d9d9;
  border: none;
}



/* Modal Footer Container */
.modalFooter {
  display: flex;
  justify-content: space-between; /* Aligns content and button to opposite sides */
  align-items: center; /* Vertically center items */
  padding-top: 35px; 
}

/* AI Type Label */
.aitype {
  display: flex;
  align-items: center;
  font-size: 20px; /* Font size for the AI type label */
  color: var(--Brand-600, #7F56D9);; /* Text color for the label */
  flex: 1; /* Takes up available space */
}

/* Cancel Button */
.cancelButton {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: var(--Basic-Forms-Input-Underline-Border-Radius, 1px) solid var(--Gray-300, #D0D5DD);
  background: var(--White, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; 
}

.cancelButton:hover {
  background-color: #e0e0e0;
}



.volumeControl {
  display: inline-block;
}

.icon {
  cursor: pointer;
}

/* Add this CSS to your stylesheet or in a style tag in your component */
:fullscreen #container {
  width: 100vw;
  height: 100vh;
  background: black; /* Adjust as needed */
}


.recordingIndicator {
  padding-left: 10px;
  display: flex;
  align-items: center;
  animation: blink 1s step-start infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
