.metricItem {
    flex: 1;
    max-width: 350px;
    width: 100%;
    border: 1px solid #e4e7ec;
    border-radius: 8px;
    background-color: #fff;
    padding: 12px;
    /* margin-left: 80px; */
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  }
  
  .metricHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .metricTitle {
    color: #101828;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  
  .optionsButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
  .optionsIcon {
    width: 20px;
    height: 20px;
  }
  
  .metricContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  
  .metricValue {
    color: #101828;
    font-size: 36px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -0.72px;
    margin: 0 0 16px 0;
  }
  
  .metricChange {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
  }
  
  .changeValue {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .positive {
    color: #027a48;
  }
  
  .negative {
    color: #b42318;
  }
  
  .changeIcon {
    width: 20px;
    height: 20px;
  }
  
  .comparisonText {
    color: #475467;
  }
  
  .metricChart {
    width: 30px;
    height: auto;
  }
  
  @media (max-width: 991px) {
    .metricItem {
      padding: 20px;
    }
  }