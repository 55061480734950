
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it is above other content */
  }
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 4px;
    position: relative;
    width: 70vw;
    height: 80%;
    overflow-y: scroll;
      overflow-x: hidden;
  }
  /* media modal */
  .Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
  }
  .ModalContent {
    background: white;
    padding: 20px;
    border-radius: 4px;
    position: relative;
    width: 70vw;
    height: 70vh;
  }
  /* ai modal */
  .modal1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
  }
  .modalContent1 {
    background: white;
    padding: 20px;
    border-radius: 4px;
    position: relative;
    width: 70vw;
    height: 70vh;
  }
  .buttonGroup {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: -40px;
    margin-left: -100px !important;
    border: 0;
  }
  
  .submitButton {
    background-color: white;
    color: black;
    border: 1px solid gray;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 40px;
    height: 6vh;
  }
  
  .cancelButton {
    border: none;
    background-color: #7f56d9;
    color: white;
    border-radius: 5px;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    height: 6vh;
    margin-top: 40px;
  }
  .buttonGroup1 {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: -40px;
    margin-left: -100px !important;
    border: 0;
  }
  
  .submitButton1 {
    background-color: white;
    color: black;
    border: 1px solid gray;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 40px;
    height: 6vh;
  }
  
  .cancelButton1 {
    border: none;
    background-color: #7f56d9;
    color: white;
    border-radius: 5px;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    height: 6vh;
    margin-top: 40px;
  }
  .buttonGroup2 {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: -40px;
    margin-left: -100px !important;
    border: 0;
  }
  
  .submitButton2 {
    background-color: white;
    color: black;
    border: 1px solid gray;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 40px;
    height: 6vh;
  }
  
  .cancelButton2 {
    border: none;
    background-color: #7f56d9;
    color: white;
    border-radius: 5px;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    height: 6vh;
    margin-top: 40px;
  }
  .heading {
    display: flex;
    justify-content: space-between; /* Distributes space between the text and icon container */
    align-items: center; /* Vertically centers the items */
    gap: 10px; /* Optional: Adjust gap between text and icon container */
  }
  
  .modalicon {
    display: flex;
    gap: 20px; /* Space between the icons */
    font-size: 25px;
  }
  .setting{
    background:skyblue;
    border-radius:15px;
    padding:3px;
  }
  .grid {
    display: grid;
    grid-template-columns: 40% 2% 72%; /* Define grid columns */
    margin-top: 12px;
  }
  
  .part1 {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    line-height: 2;
  }
  
  .image {
    width: 100%;
    max-width: 198px;
  }
  
  p{
    text-align: left !important; /* Center align text inside strong tag */
    font-size: 18px;
  }
  .verticalline{
    border-left: 2px solid black;
    height: 400px;
    margin-left: -140px;
  }
  .part2{
    margin-left: -90px;
  }
  .tabGroup {
    display: flex;
    margin-left: -40px !important;
    /* margin-left: 5%; */
    width: 30vw;
    /* border: 1px solid #d0d5dd; */
    border-radius: 5px;
  }
  
  .tab {
    padding: 10px;
    border: 1px solid #d0d5dd;
    background-color: #fff;
    color: #344054;
    font-size: 15px;
    font-weight: 200;
    cursor: pointer;
    height: 5vh;
  }
  
  .tab.active {
    background-color: #faf6ff;
  }
  .notify {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-left: -40px;
  }

  .notificationItem {
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

  
.toggleSwitch {
    display: inline-block;
    position: relative;
    width: 40px; /* Width of the switch */
    height: 24px; /* Height of the switch */
    margin-left: 0; /* Reset the large margin */
}
 

  .toggleInput {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .toggleLabel {
    position: absolute;
    cursor: pointer;
    background-color: #ccc; /* Switch background when off */
    border-radius: 34px; /* Make it round */
    transition: background-color 0.3s;
    width: 100%;
    height: 100%;
}
  
  
.slider {
    position: absolute;
    cursor: pointer;
    background-color: white; /* Background color of the slider */
    border-radius: 50%;
    transition: transform 0.3s;
    width: 16px; /* Slider width */
    height: 16px; /* Slider height */
    left: 4px; /* Position from the left */
    top: 4px; /* Position from the top */
}
 
  
  .toggleInput:checked + .toggleLabel {
    background-color: #7f56d9;
  }
 
  
  .toggleInput:checked + .toggleLabel .slider {
    transform: translateX(16px);
  }
  
 
 
  .inputContainer, .selectContainer {
    flex: 1;
    margin-left: auto;
  }
  
  .inputField {
    padding: 5px 10px; /* Padding inside the notify field */
    border: 1px solid #d0d5dd; /* Border color and style */
    border-radius: 4px; /* Rounded corners */
    background-color: #fff; /* Background color */
    font-size: 14px; /* Font size */
    cursor: text; /* Text cursor when focusing */
    width: 15vw; /* Set width of the input field */
    margin-left: 180px;
  }
  
  /* Optional: Additional styling for the input field */
  .inputField:focus {
    border-color: #7f56d9; /* Change border color on focus */
    outline: none; /* Remove default outline */
  }
 
  

  
  .selectInput {
    width: 15vw;
    padding: 5px 10px; /* Padding inside the select box */
    border: 1px solid #d0d5dd; /* Border color and style */
    border-radius: 4px; /* Rounded corners */
    background-color: #fff; /* Background color */
    font-size: 14px; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    margin-left: 150px;
  }
  
  /* Optional: Style for the select options */
  .selectInput option {
    padding: 5px; /* Padding inside each option */
  }
  
.footer{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}
.reset{
  margin-left: -180px;
}
ul{
  list-style-type: none;
  margin-top: 40px;
}
.part1 span{color: #62853f; margin-right: 300px; margin-top: 20px;}
.part1 li{
  font-size: 16px;
  line-height: 5vh;
  text-align: left;
}
.verticalline{
  border-left: 2px solid black;
  height: 400px;
  margin-left: -140px;
}
.progressbar{
  display: grid;
  grid-template-columns: 50% 50%;
}
 
input[type='range']{
  width: 50%;
  color: #7534ff;
}

.media{
  margin-left: -40px;
  line-height: 2vh;
}

  @media (max-width: 768px) {
    .modalContent {
      width: 90vw; /* Full width on smaller screens */
      font-size: 10px;
    }
  
    .grid {
      grid-template-columns: 1fr; /* Stack columns vertically */
    }
  
    .footer {
      grid-template-columns: 1fr 1fr; /* Adjust for smaller screens */
    }  
  }

  .tab {
    padding: 10px;
    border: 1px solid #d0d5dd;
    background-color: #fff;
    color: #344054;
    font-size: 15px;
    font-weight: 200;
    cursor: pointer;
    height: 5vh;
  }
  
  .tab.active {
    background-color: #faf6ff;
    border-bottom: 2px solid #7f56d9; /* Add active tab indicator */
  }
  