.container {
  display: flex;
  /* margin-top: 8px; */
  width: 100%;
  padding-bottom: 104px;
  flex-direction: column;
  font-family: Inter, sans-serif;
}

@media (max-width: 991px) {
  .container {
    max-width: 100%;
    margin-top: 40px;
    padding-bottom: 100px;
  }
}

.header {
  display: flex;
  margin-right: 18px;
  align-items: start;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .header {
    max-width: 100%;
    margin-right: 10px;
  }
}

.deviceInfo {
  display: flex;
  flex-direction: column;
  color: #000;
}

.deviceName {
  display: flex;
  gap: 10px;
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
}

@media (max-width: 991px) {
  .deviceName {
    margin-right: 10px;
  }
}

.deviceDate {
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
}

.datePickerWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 15px;
  color: #6b7280;
  font-weight: 500;
  letter-spacing: 0.07px;
}

.datePickerInput {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.datePickerInputInner {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}

.datePickerInputField {
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  width: 100%;
  gap: -20px;
  overflow: hidden;
  justify-content: start;
}

.datePickerLeading {
  align-self: stretch;
  display: flex;
  min-height: 44px;
  gap: 10px;
  margin: auto 0;
  padding: 14px 0;
}

.datePickerContent {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  align-items: center;
  justify-content: start;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
}

.datePickerPlaceholder {
  align-self: stretch;
  flex: 1;
  min-width: 240px;
  width: 100%;
  gap: 10px;
  margin: auto 0;
  padding: 14px 16px;
}

.icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
  margin: auto 0;
}

.deviceVisual {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  margin-top: 17px;
  min-height: 538px;
  width: 100%;
  align-items: start;
  gap: 11px 50px;
  font-size: 12px;
  color: #62853f;
  font-weight: 400;
  white-space: nowrap;
  justify-content: start;
  flex-wrap: wrap;
}


.playerContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; /* High z-index to bring it to the front */
}


@media (max-width: 991px) {
  .deviceVisual {
    max-width: 100%;
    white-space: initial;
  }
}

.deviceImage {
  position:relative;
  aspect-ratio: 1.88;
  object-fit: contain;
  object-position: center;
  width: 1008px;
  height: 720px;
  border-radius: 8px;
  align-self: end;
  z-index: 0;
  min-width: 240px;
  flex-grow: 1;
}

@media (max-width: 991px) {
  .deviceImage {
    max.mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 1013px;
      height: 720px;
      background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black mask */
      z-index: 9999;
      pointer-events: none; /* Ensures the mask doesn't block user interactions */
    }-width: 100%;
  }
}

.centerIcon {
  aspect-ratio: 1.09;
  object-fit: contain;
  object-position: center;
  width: 24px;
  position: absolute;
  z-index: 0;
  left: 495px;
  right: 494px;
  top: 258px;
  bottom: 258px;
}

.statusBadge {
  align-self: stretch;
  border-radius: 5px;
  background-color: #f5ffe4;
  position: absolute;
  min-height: 17px;
  gap: 10px;
  top: 13px;
  width: 91px;
  height: 17px;
  flex-grow: 1;
  padding: 1px 17px;
}

@media (max-width: 991px) {
  .statusBadge {
    white-space: initial;
  }
}

.highlightBox {
  align-self: end;
  z-index: 10;
  display: flex;
  width: 88px;
  height: 167px;
  margin: -271px 193px 0 0;
  border: 1px solid #3a28ff;
}

@media (max-width: 991px) {
  .highlightBox {
    margin: -200px 10px 0 0;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.mediaControlItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0.05px;
  font: 300 10px Inter, sans-serif;
}

.icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
}

.label {
  align-self: stretch;
  margin-top: 6px;
}

@media (max-width: 991px) {
  .icon {
    margin-top: 40px;
  }

  .label {
    margin: 0 3px;
  }
}

.mediaControls {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.controlItem:not(:first-child) {
  margin-top: 64px;
}

@media (max-width: 991px) {
  .controlItem:not(:first-child) {
    margin-top: 0;
  }
}



.iconContainer {
  display: flex;
  align-items: center;
  width: 100%;
}
.sdIcon, .cloudIcon {
  width: 48px;
  height: 24px;
  font-size: 12px;
  color: #000000;
  padding: 3px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.ic {
  text-align: center;
}

.iconOn {
  background-color: #ffffff;
  border: 1px solid #000000;
}

.iconOff {
  background-color: #d9d9d9;
  border: none;
}