/* Updated styles for header and modal */
.header {
  position: fixed; /* Fixes header position */
  top: 0;
  left: 0;
  width: 100%;
  padding: 14px 40px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000; /* Make sure header stays above other elements */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); Optional: Add shadow for better visibility */
}

/* Adjust the main content to account for the header */
body {
  padding-top: 60px; /* Adjust based on header height */
}

.logo {
  width: 120px; 
}

.headerActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.icon {
  width: 24px;
  height: 24px;
}

.addDeviceButton {
  border-radius: 8px;
  border: 1px solid #7f56d9;
  background: #7f56d9;
  color: #fff;
  padding: 8px 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.addDeviceButton:hover {
  background-color: #6b46d1;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: #fff;
  padding: 40px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modalTitle {
  font-size: 24px;
  margin-bottom: 10px;
}

.modalSubtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
}

.formGroup {
  margin-bottom: 20px;
}

.label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}

.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.circle {
  position: absolute;
  right: 10px;
  top: 35px;
  font-size: 20px;
  color: #7f56d9;
}

.helperText {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}

.buttonGroup {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.submitButton {
  background-color: #7f56d9;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #6b46d1;
}

.cancelButton {
  background-color: #f5f5f5;
  color: #333;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.cancelButton:hover {
  background-color: #e0e0e0;
}

/* Responsive styles */
@media (max-width: 768px) {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end !important;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 5px;
  }

  .logo {
    width: 90px;
  }

  .headerActions {
    margin-left: 10px;
    gap: 10px;
     justify-content: flex-end !important; 
    width: 100%;
  }
 
  .modalContent {
    width: 90%;
  }

  .circle {
    right: 10px;
  }
}


.dropdownMenu {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  width: 150px;
  z-index: 10;
}

.dropdownMenu ul {
  list-style: none;
  margin: 0;
  padding: 10px 0;
}

.dropdownMenu li {
  padding: 10px 20px;
}

.dropdownMenu li a {
  color: #333;
  text-decoration: none;
  font: 600 14px Inter, sans-serif;
  display: block;
}

.dropdownMenu li:hover {
  background-color: #7f56d9;
  color: #fff;
  cursor: pointer;
}




