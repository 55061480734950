.metricGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 12px;
  }
  
  @media (max-width: 991px) {
    .metricGroup {
      flex-direction: column;
    }
  }