.eventsDesktop {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 15px 74px 0 0 ;
  margin: 0;
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  max-width: 1403px;
}

.mainContent {
  flex-grow: 1;
  margin-top: 20px;
  margin-left: 40px;
  padding: 0;
}

.pageTitle {
  color: #000;
  font-size: 36px;
  font-weight: 600;
  font-family: sans-serif;
  line-height: 1;
}

.pageDescription {
  color: #475467;
  font-size: 16px;
  margin-top: 15px;
}

.eventCardGrid {
  width: 80vw !important;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}
.tabGroup {
  width: 24vw;
  display: flex;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  margin-left: 70%;
  margin-top: -30px;
}

.tab {
  padding: 10px 16px;
  font-size: 14px;
  color: #344054;
  background-color: #fff;
  border: none;
  border-right: 1px solid #d0d5dd;
  cursor: pointer;
}

.tab.active {
  background-color: #faf6ff;
}
.eventList{
  display: none;
}
.refresh{
  display: none;
}
@media(max-width:768px){
  .tabGroup{
    width: 40vw !important;
  }
}
@media (max-width: 991px) {
  .pageTitle{
    margin-left: -28px;
    margin-top: 12px;
  }
  .pageDescription{
    margin-left: -28px;
    margin-top: -5px;
  }
  .tabGroup{
    margin-left: -28px;
    margin-top: 10px;
    width: 72vw !important;
  }
  .tab{
    font-size: 11px;
  }
  .eventsDesktop {
    padding-right: 20px;
    position: relative;
  }
  
  .contentWrapper {
    flex-direction: column;
  }
  .eventCardGrid{
    margin-left: -60px;
    width: 100vw !important;
    justify-content: center;
  }
  
}
@media(max-width: 480px){
  .eventCardGrid{
    display: none;
  }
  .pageDescription{
    display: none;
  }
  .eventList {
    display: block;
    width: 85vw;
    list-style-type: none;
    padding: 10px;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    border-radius: 4px;
    margin-left: -10px;
  }
  
  .eventItem {
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid black;
    border-radius: 8px;
    margin-top: 20px;
    padding: 3px;
  }
  
  .eventInfo {
    display: flex;
    flex-direction: row;
  }

  .eventType {
    display: block; /* Make sure eventType spans full width */
    margin-left: 180px; /* Space between type and time */
  }
  .deviceName{
    margin-top: 4px;
    font-weight: 700 !important;
  }
  
  .eventTime {
    display: block; /* Ensure eventTime appears below eventType */
    margin-left: 220px !important;
    margin-top: 8px;
  }
  .refresh{
    display: block;
   margin-left: 45% !important;
  }
  .pageTitle{
    display: flex;
  }
  .tabGroup{
    width: 75vw;
  }
}
@media(max-width: 320px){
  .mainContent{
    margin-top: -20px;
  }
  .pageTitle{
    display: flex;
    font-size: 30px;
  }
  .refresh{
    display: block;
    margin-left: 40% !important;
    font-size: 20px;
  }
  .eventType{
    display: block; /* Make sure eventType spans full width */
    margin-left: 100px; /* Space between type and time */
  }
  .eventTime {
    display: block; /* Ensure eventTime appears below eventType */
    margin-left: 160px !important;
    margin-top: 8px;
  }
  .pageDescription{
    display: none;
  }
  .tabGroup{
    width: 94vw !important;
    margin-left: -30px;
  }
}
