.eventListSection {
  flex-grow: 1;
  margin-top: 20px;
  margin-left: 40px;
  padding: 0;
}

.pageTitle {
  color: #000;
  font-size: 36px;
  font-weight: 600;
  font-family: sans-serif;
  line-height: 1;
}

.pageDescription {
  color: #475467;
  font-size: 16px;
  margin-top: 15px;
}

.tabGroup {
  width: 24vw;
  display: flex;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  margin-left: 70%;
  margin-top: -30px;
}

.tab {
  padding: 10px 16px;
  font-size: 14px;
  color: #344054;
  background-color: #fff;
  border: none;
  border-right: 1px solid #d0d5dd;
  cursor: pointer;
}

.tab.active {
  background-color: #faf6ff;
}

.eventListHeader {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 29px;
}

.dateFilter {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 23px;
}

.dateInput {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 14px 16px;
  font-size: 15px;
  color: #6b7280;
}

.clearButton {
  background: none;
  border: none;
  color: #757575;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.downloadButton {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 14px;
  background: none;
  border: none;
  color: #757575;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.downloadIcon {
  width: 24px;
  height: 24px;
}

.eventList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.eventItem {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 23px;
}

.eventInfo {
  display: flex;
  gap: 40px;
  align-items: center;
}

.deviceName {
  font-size: 16px;
  color: #000;
}

.eventType {
  display: flex;
  align-items: center;
  gap: 17px;
}

.eventIcon {
  width: 42px;
  height: 42px;
}

.eventTime {
  color: #8b8b8b;
  font-size: 14px;
}

.loadMoreButton {
  display: block;
  margin: 40px auto 0;
  background: none;
  border: none;
  color: #757575;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.datePickerWrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.dateInput {
  padding: 10px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 15px;
  width: 250px;
  /* Adjust width as needed */
  box-sizing: border-box;
}

.calender {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #888;
  /* Adjust color as needed */
  cursor: pointer;
}

.noData {
  color: rgb(138, 138, 138);
  font-size: 1.2em;
  text-align: center;
  margin-top: 20px;
}


@media (max-width: 991px) {
  .eventListHeader {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .dateFilter {
    width: 100%;
  }

  .dateInput {
    flex-grow: 1;
  }

  .eventInfo {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}