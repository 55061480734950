.dashboardContainer {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 30px 73px 8px 0;
  }
  
  @media (max-width: 991px) {
    .dashboardContainer {
      /* padding-right: 20px; */
      padding: 0px;
      margin: 10px;
    }
  }
  
  .contentHeader {
    display: flex;
    width: 100%;
    gap: 20px;
    font-family: Inter, sans-serif;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .mainContent {
    display: flex;
    flex-grow: 1;
    flex: 1;
    /* width: calc(100% - 100px); */
    /* margin-left: 130px;  */
    gap: 40px;
    flex-wrap: wrap;
  }
  @media(max-width:768px){
    
  }
  
  @media (max-width: 991px) {
    .mainContent {
      max-width: 100%;
      margin-top: 40px;
      flex-direction: column;
    }
    .header{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
    .iconButton{
      display: none;
    }
    .headerActions{
      justify-content: flex-end;
      width: 100%;
    }
    .sidebar{
      display: none;
    }
    .deviceListTitle,.deviceListSubtitle{
      font-size:30px;
      margin-left: 20px;
      margin-top: 40px;
    }
    .deviceListDescription p{
      margin-left: 20px;
      margin-top: 30px;
    }
    .modal {
      width: 100%; /* Adjusted width */
    }
    .modalContent {
      width: 100%; /* Adjusted width */
      height: 80%;
    }
  
    .buttonGroup {
      flex-direction: column;
      gap: 0.5rem;
    }
    .input{
      position: relative;
    }
    .circle{
      position: absolute;
      margin-left: 60px;
    }
  }
  
  .deviceListSection {
    flex-grow: 1;
    width: calc(100% - 100px);
    /* margin-left: 130px;  */
    gap: 40px;
    flex-wrap: wrap;
  }
  
  .deviceListHeader {
    display: flex;
    gap: 40px;
    font: 600 28px/1 Inter, sans-serif;
    margin-top: -60px;
  }

  
  .deviceListTitle {
    color: #000;
  }
  
  .deviceListSubtitle {
    color: #a1a1a1;
  }
  
  .deviceListDescription {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -40px;
  }
  
  .deviceListDescription p {
    color: #475467;
    font: 400 16px Inter, sans-serif;
  }
  
  .deviceListActions {
    display: flex;
    gap: 40px;
  }
  
  .deviceGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .deviceCard {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .deviceImage {
    width: 100%;
    aspect-ratio: 1.65;
    object-fit: cover;
  }
  
  .deviceInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  
  .deviceName {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  
  .deviceIcon {
    width: 32px;
    height: 20px;
  }
  
  .deviceSerial {
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 0 10px;
  }
  
  .serialIcon {
    width: 13px;
    height: 13px;
  }
  
  .deviceSnapshot {
    padding: 0 10px;
    margin: 5px 0;
  }
  
  .deviceStatusIcon {
    position: absolute;
    top: 93px;
    left: 153px;
    width: 24px;
    height: 24px;
  }
  
  .deviceStatus {
    position: absolute;
    top: 13px;
    left: 13px;
    border-radius: 5px;
    padding: 1px 17px;
    font-size: 10px;
  }
  
  .connected {
    background-color: #f5ffe4;
    color: #62853f;
  }
  
  .disconnected {
    background-color: #fff6f6;
    color: #aa3030;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it is above other content */
  }
  
  /* Modal content */
  .modalContent {
    background: white;
    padding: 40px;
    border-radius: 4px;
    position: relative;
    width: 30vw;
  }
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .formGroup {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .label {
    display: block;
    margin-top: 20px;
  }
  
  .input {
    position: relative;
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 14px;
  }
  
  .helperText {
    display: block;
    margin-top: 10px !important; 
    color: black;
    font-size: 14px; 
  }
  .buttonGroup {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: -20px;
    border: 0;
  }
  
  .submitButton {
    background-color: #7f56d9;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 40px;
  }
  
  .cancelButton {
    background-color: white;
    color: black;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 40px;
  }  
  .circle{
    position:absolute;
    margin-top:-26px;
    font-size:20px;
    margin-left:82%;
  }



/* /////multiple///// */



.titleSubtitleContainer {
  display: flex;
  gap: 20px;
  align-items: center; /* Vertically align items */
}
.title {
  color: #000;
  font-size: 36px;
  font-weight: 600;
  line-height: 0.2;
}
.subtitle {
  color: #a1a1a1;
  font-size: 36px;
  font-weight: 600;
  line-height: 0.2;
}
.description {
  color: #475467;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}
