.container-shell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
}

#container {
  width: 100%;
  max-width: 100%;
  height: auto;
  position: relative;
  border: 2px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  background-color: #000;
}

@media (min-width: 768px) {
  #container {
      width: 90%;
      height: auto;
  }
}

@media (min-width: 1024px) {
  #container {
      width: 80%;
      height: auto;
  }
}

.deviceImage {
  position:relative;
  width: 70vw;
  border-radius: 8px;
  height: 60vh;
  /* margin-left: 60px; */
}
