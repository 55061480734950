.dashboardContainer {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 30px 73px 11px 0;
  }
  
  @media (max-width: 991px) {
    .dashboardContainer {
      padding-right: 20px;
    }
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .logo {
    width: 102px;
    height: auto;
    margin-left: 40px;
  }
  
  .headerActions {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .iconButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
  }
  
  .icon {
    width: 24px;
    height: 24px;
  }
  
  .addDeviceButton {
    background-color: #7f56d9;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 10px 18px;
    font-weight: 600;
    cursor: pointer;
  }
  
  .mainContent {
    display: flex;
    gap: 40px;
  }
  
  .sidebar {
    width: 200px;
    margin-left: 40px;
    margin-top: 60px;
  }
  
  .menuList {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .menuItem {
    margin-bottom: 20px;
  }
  
  .menuLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000;
    font-size: 16px;
  }
  
  .menuIcon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  
  .deviceSection {
    flex-grow: 1;
  }
  
  .deviceView {
    margin-bottom: 20px;
    margin-left: -60px;
  }
  
  .deviceHeader {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr; /* Adjust grid columns as needed */
    align-items: center; /* Align items vertically center */
    margin-bottom: 10px;
    margin-top: -20px; /* Remove negative margin to prevent layout issues */
    margin-left: 80px;
  }
  
  .deviceTitle {
    font-size: 36px;
    font-weight: 600;
  }
  
  .deviceTime {
    font-size: 16px;
    margin-top: 80px;
    margin-left: -290px;
  }
  
  .dateRangePicker {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between the date picker and icons */
    margin-top: 60px;
  }
  
  .datePickerWrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: -180px;
  }
  
  .dateInput {
    padding: 10px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    font-size: 15px;
    width: 250px; /* Adjust width as needed */
    box-sizing: border-box;
  }
  
  .calender {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: #888; /* Adjust color as needed */
    cursor: pointer;
  }
  
  .iconButton {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .icon {
    width: 20px; /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
  }
  
  
  .iconButton {
    background: none; /* Remove background for a cleaner look */
    border: none; /* Remove border */
    cursor: pointer; /* Pointer cursor to indicate clickability */
  }
  
  .icon {
    width: 20px; /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
  }
  
  
  .deviceComponent {
    position: relative;
  }
  
  .deviceImage {
    width: 70vw;
    border-radius: 8px;
    height: 60vh;
    margin-left: 60px;
  }
  
  .overlayIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .statusBadge {
    position: absolute;
    top: 10px;
    left: 80px;
    background-color: #f5ffe4;
    color: #62853f;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
  }

  .deviceStatus {
    position: absolute;
    top: 13px;
    left: 13px;
    border-radius: 5px;
    padding: 1px 17px;
    font-size: 10px;
  }
  
  .connected {
    background-color: #f5ffe4;
    color: #62853f;
  }
  
  .disconnected {
    background-color: #fff6f6;
    color: #aa3030;
  }
  
  .timeline {
    margin-bottom: 20px;
  }
  
  .timelineHeader,
  .timelineBody {
    display: flex;
    justify-content: space-between;
    height: 1%;
  }
  
  .dateColumn,
  .timeColumn {
    flex: 1;
    text-align: center;
  }
  
  .date,
  .timeLabel {
    font-size: 10px;
    color: #000;
  }
  
  .timeMark {
    height: 33px;
    border-left: 1px solid #5a5a5a;
    margin: 0 auto;
  }
  
  .eventIndicator {
    height: 2px;
    background-color: #7534ff;
    margin-top: 10px;
  }
  
  /* .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */
   .actionButtons{
    display: flex;           /* Use flexbox for layout */
    flex-direction: column;  /* Arrange buttons in a column */
    align-items: center;     /* Center align the buttons horizontally */
    justify-content: flex-start; /* Align buttons at the start of the container */
    position: absolute;      /* Use absolute positioning for better control */
    top: 25%;                /* Adjust this value to position the buttons vertically */
    right: 5%;               /* Adjust this value to position the buttons horizontally */
    font-size: 20px;         /* Keep the font size */
    z-index: 1000; 
  }
  
  .playbackControls{
    display: flex;
    margin-left: 50px;
  }
  .actionButton{
    background: none; 
    border: none;
    cursor: pointer;
    display: flex; /* Use flexbox for this button */
    flex-direction: column; /* Stack icon and text vertically */
    align-items: center; /* Center align the items */
    margin-top: 10px; /* Space between buttons */
  }
  .controlButton{
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-top: -10px;
  }
  .actionIcon{
    width: 24px;
    height: 28px;
    font-size: 30px;
  }
  .controlIcon {
    width: 24px;
    height: 24px;
  }
  .actionButton .icon {
    font-size: 24px; 
}
  .actionLabel {
    font-size: 10px;
    margin-top: 8px;
  }
  
  @media (max-width: 991px) {
    .mainContent {
      flex-direction: column;
    }
  
    .sidebar {
      width: 100%;
    }
  }