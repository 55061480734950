.eventCard {
    position: relative;
    width: 340px;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .duration {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #f5ffe4;
    color: #62853f;
    padding: 2px 8px;
    border-radius: 5px;
    font-size: 12px;
  }
  
  .eventImage {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .eventInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  .eventTitle {
    font-size: 20px;
    font-weight: 600;
    color: #000;
  }
  
  .infoIcon {
    width: 24px;
    height: 24px;
  }
  
  .deviceInfo {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
  }
  
  .deviceId {
    font-size: 14px;
    color: #000;
  }
  
  .statusIcon {
    width: 13px;
    height: 13px;
  }
  
  .eventLength {
    font-size: 14px;
    color: #000;
    margin-top: 5px;
  }
  
  .playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  
  .connectionStatus {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #f5ffe4;
    color: #62853f;
    padding: 2px 8px;
    border-radius: 5px;
    font-size: 12px;
  }
  
  @media (max-width: 991px) {
    .eventCard {
      width: 100%;
    }
  }