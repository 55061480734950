.eventList {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 50px;
    margin-top: 30px;
  }
  
  .eventItem {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .deviceName {
    width: 100px;
    font-weight: 600;
  }
  
  .eventDetails {
    display: flex;
    align-items: center;
    gap: 17px;
    flex: 1;
  }
  
  .eventIcon {
    aspect-ratio: 1;
    width: 42px;
  }
  
  .eventType {
    font-size: 16px;
  }
  
  .eventTime {
    font-size: 14px;
    color: #8b8b8b;
  }
  
  @media (max-width: 991px) {
    .eventItem {
      flex-direction: column;
      align-items: flex-start;
      margin-left: -40px;
    }
  
    .eventTime {
      align-self: flex-end;
      margin-top: -50px;
    }
  }