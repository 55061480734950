.multiScreenDesktop {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 90px 73px 8px 0;
  }
  
  .mainContent {
    display: flex;
    flex: 1;
    width: calc(100% - 100px);
    /* margin-left: 130px;  */
    gap: 40px;
    flex-wrap: wrap;
  }
    
  .header {
    display: flex;
    width: 100%;
    max-width: 1403px;
    gap: 20px;
    color: #fff;
    flex-wrap: wrap;
    justify-content: space-between;
    font: 600 16px Inter, sans-serif;
    margin-top: 10px;
  }
  
  .logo {
    aspect-ratio: 4.85;
    object-fit: contain;
    object-position: center;
    width: 102px;
    max-width: 100%;
    margin-left:40px;
  }
  
  .headerActions {
    display: flex;
    align-items: center;
    gap: 29px;
  }
  
  .icon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    align-self: stretch;
    margin: auto 0;
  }
  
  .addDeviceButton {
    border-radius: 8px;
    border: 1px solid #7f56d9;
    background: #7f56d9;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #fff;
    padding: 10px 18px;
    font: inherit;
    cursor: pointer;
  }
  
  .sidebar {
    flex: 0 0 210px;
    align-self: start;
    color: #000;
    font: 400 16px Inter, sans-serif;
    margin-top: 40px;
    margin-left: 40px;
  }
  
  .menuList {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .menuItem {
    display: flex;
    align-items: center;
    gap: 11px;
    margin-bottom: 28px;
    cursor: pointer;
  }
  
  .menuIcon {
    aspect-ratio: 0.92;
    object-fit: contain;
    object-position: center;
    width: 24px;
  }
  
  .contentHeader {
    display: flex;
    width: 100%;
    gap: 20px;
    font-family: Inter, sans-serif;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .title {
    color: #000;
    font-size: 36px;
    font-weight: 600;
    line-height: 0.2;
  }
  
  .description {
    color: #475467;
    font-size: 16px;
    font-weight: 400;
    margin-top: 15px;
  }
  
  .screenSelector {
    display: flex;
    align-items: center;
    gap: 33px;
    font-size: 15px;
    color: #1f2937;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.07px;
  }
  
  .selectorButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    padding: 14px 16px;
    gap: 10px;
    font: inherit;
    cursor: pointer;
  }
  
  .dropdownIcon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 14px;
  }
  
  .fullscreenIcon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    cursor: pointer;
  }
  
  .screenGrid {
    display: grid;
    grid-template-columns: 100% 100%;
    gap: 20px;
    margin-top: -30px;
  }
  
  .screenCard {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .imageWrapper {
    position: relative;
    min-height: 220px;
  }
  
  .screenImage {
    position: absolute;
    inset: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .connectionStatus {
    position: absolute;
    top: 13px;
    left: 13px;
    border-radius: 5px;
    background-color: #f5ffe4;
    padding: 1px 17px;
    font-size: 12px;
    color: #62853f;
  }
  
  .overlayIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 1.04;
    object-fit: contain;
    object-position: center;
    width: 24px;
  }
  
  .cardFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #fff;
  }
  
  .cardTitle {
    font-size: 20px;
    color: #000;
    font-weight: 600;
    line-height: 1.2;
    margin: 0;
  }
  
  .moreIcon {
    aspect-ratio: 1.04;
    object-fit: contain;
    object-position: center;
    width: 25px;
    cursor: pointer;
  }
  /* Modal background */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it is above other content */
  }
  
  /* Modal content */
  .modalContent {
    background: white;
    padding: 40px;
    border-radius: 4px;
    position: relative;
    width: 30vw;
  }
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .formGroup {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .label {
    display: block;
    /* margin-bottom: 2px; */
    margin-top: 20px;
  }
  
  .input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 14px;
  }
  
  .helperText {
    display: block;
    margin-top: 10px !important; 
    color: black;
    font-size: 14px; 
  }
  .buttonGroup {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: -20px;
    border: 0;
  }
  
  .submitButton {
    background-color: #7f56d9;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 40px;
  }
  
  .cancelButton {
    background-color: white;
    color: black;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 40px;
  }  

  
  
  @media (max-width: 991px) {
    .multiScreenDesktop {
      padding-right: 20px;
    }
  
    .header {
      max-width: 100%;
    }
    .sidebar{
      display: none;
    }
    .title{
      margin-top: -30px;
      margin-left: 20px;
    }
  
    .mainContent {
      max-width: 100%;
      margin: 40px 7px 0 0;
    }
  
    .contentHeader {
      max-width: 100%;
      margin-right: 10px;
    }
  
    .description {
      max-width: 100%;
      margin-left: 20px;
    }
  
    .screenSelector {
      white-space: initial;
      margin-left: 20px;
    }
  
    .screenGrid {
      width: 100%;
      max-width: 100%;
      margin-left: 5%;
      grid-template-columns: 1fr;
    }
  
    .imageWrapper {
      padding: 0 20px 100px 0;
    }
  
    .overlayIcon {
      margin: 40px 0 10px;
    }
  }