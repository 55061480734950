.profileDesktop {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 15px 74px 0 0 ;
  }
  
  .mainContent {
    display: flex;
    width: 100%;
    max-width: 1403px;
    gap: 20px;
  }
  
  .profileSection {
    flex: 1;
    margin-left: 40px;
    margin-top: 20px;
  }
  
  @media (max-width: 991px) {
    .profileDesktop {
      padding: 0 20px 100px 0;
    }
    
    .mainContent {
      flex-direction: column;
    }
  }