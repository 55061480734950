/* Sidebar.module.css */

.sidebar {
    width: 200px; /* Fixed width for the sidebar */
    height: 100vh; /* Full height of the viewport */
    position: fixed; /* Fix the sidebar to the left side */
    top: 72px;
    left: 0;
    /* background-color: #fff; */
    padding: 20px 40px;
   
    z-index: 1000; /* Ensure it stays above the content */
  }
  
  .menuList {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .menuItem {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  /* .menuItem:hover {
    background-color: #e2e6ea;
  } */
  
  .menuIcon {
    width: 24px;
    height: 24px;
    margin-right: 11px;
  }
  
  .menuLabel {
    vertical-align: middle;
  }
  