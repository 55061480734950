.profileContainer {
    display: flex;
    flex-direction: column;
    font-family: Inter, sans-serif;
  }
  
  .profileHeader {
    margin-bottom: 15px;
  }
  
  .profileTitle {
    color: #000;
    font-size: 36px;
    font-weight: 600;
    line-height: 1;
  }
  
  .contentSection {
    padding-left: 5px;
    font-size: 16px;
    font-weight: 400;
  }
  
  .sectionTitle {
    color: #475467;
    margin-bottom: 31px;
  }
  
  .infoWrapper {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .contactInfo {
    display: flex;
    gap: 23px;
    color: #475467;
  }
  
  .contactList {
    list-style-type: none;
    padding: 0;
  }
  
  .contactItem {
    margin-bottom: 25px;
  }
  
  .contactLabel {
    display: block;
  }
  
  .avatarWrapper {
    display: flex;
    align-items: flex-start;
    font-size: 20px;
    color: #1f2937;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.1px;
  }
  
  .avatarLarge {
    border-radius: 999px;
    background-color: #fff;
    width: 62px;
    height: 62px;
    border: 1px solid #1f2937;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* .bindSection {
    color: #949fae;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
  }
  
  .bindEmail {
    margin-bottom: 25px;
  }
  
  .bindWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 26px;
  }
  
  .bindButton {
    margin-top: 25px;
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font: inherit;
    padding: 0;
  } */
  
  @media (max-width: 991px) {
    .profileContainer {
      max-width: 100%;
      /* margin-top: 40px; */
    }
  
    .contentSection {
      max-width: 100%;
    }
  
    .infoWrapper {
      white-space: initial;
    }
  
    .contactInfo {
      white-space: initial;
    }
  
    .bindSection {
      margin-top: 40px;
      white-space: initial;
    }
  
    .bindWrapper {
      padding-left: 20px;
    }
  }
  @media(max-width: 320px){
    .sectionTitle{
        display: none;
    }
    .profileTitle{
        font-size: 30px;
        margin: 20px;
    }
    .avatarWrapper{
        margin: 10px;
        margin-top: -20px;
    }
    .name{
        margin-left: 20px !important;
        margin-top: 20px;
        font-size: 20px !important;
    }
    .contactItem{
        margin-top: 30px !important;
        margin-left: -250px !important;
        display:grid;
        grid-template-columns: 2fr 2fr;
        line-height: 0.1;
        font-weight: bold;
    }
    .contactValue{
        color: gray;
    }
    .contactlist{
      font-size: 14px;
      margin-top: 160px !important;
    }
    /* .contactvalue{ 
      font-size: 8px !important;
      margin-left : 30px !important;
      display: block !important;
    } */
    .contactlabel{
      font-size: 8px !important;
      display: block;
      margin-left: -10px !important;
    }
    .contactitem{
      gap: 5px !important;
      word-break: break-word !important;
    }
    .contactlist h4{
      margin-left: -260px !important;
    }
    .contactvalue{
      font-size: 8px !important;
      width: 100% !important;
      display: block !important;
      overflow-wrap: break-word !important;
      word-break: break-word !important;
    }
  }
  @media(max-width:600px){
    .sectionTitle{
        display: none;
    }
    .profileTitle{
        font-size: 30px;
        margin: 20px;
    }
    .avatarWrapper{
        margin: 10px;
        margin-top: -20px;
    }
    .name{
        margin-left: 20px !important;
        margin-top: 20px;
        font-size: 20px !important;
    }
    .contactItem{
        margin-top: 40px;
        margin-left: -250px !important;
        display:grid;
        grid-template-columns: 2fr 2fr;
        line-height: 0.1;
        font-weight: bold;
    }
    .contactValue{
        color: gray;
    }
    .contactlist{
      margin-top: 220px;
      margin-left: -80px;
    }
    .contactitem{
      margin-top: 40px;
      margin-left: -255px !important;
      display:grid;
      grid-template-columns: 2fr 3fr;
      line-height: 0.1;
      font-weight: bold;
      gap: 15px;
  }
  .contactlabel{
    width: 100%;
  }
  .contactvalue{
    width: 100%;
  }
  .contactlist h3{
    display: inline;
    width: 100% !important;
    margin-left: -250px;
  }
  .heading {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 10px;
    margin-left: -250px !important;
  }
}
@media(max-width: 768px){
  .sectionTitle{
    display: none;
}
.profileTitle{
    font-size: 30px;
    margin: 20px;
}
.avatarWrapper{
    margin: 10px;
    margin-top: -20px;
}
.name{
    margin-left: 20px !important;
    margin-top: 20px;
    font-size: 20px !important;
}
.contactItem{
    margin-top: 40px;
    margin-left: -250px !important;
    display:grid;
    grid-template-columns: 2fr 2fr;
    line-height: 0.1;
    font-weight: bold;
}
.contactValue{
    color: gray;
}
.contactlist{
  margin-top: 220px;
  margin-left: -80px;
}
.contactitem{
  margin-top: 40px;
  margin-left: -255px !important;
  display:grid;
  grid-template-columns: 2fr 3fr;
  line-height: 0.1;
  font-weight: bold;
  gap: 15px;
}
.contactlabel{
width: 100%;
}
.contactvalue{
width: 100%;
}
.contactlist h3{
display: inline;
width: 100% !important;
margin-left: -250px;
}
.heading {
font-size: 1.2em;
font-weight: bold;
margin-top: 40px;
margin-bottom: 10px;
margin-left: -250px !important;
}
}