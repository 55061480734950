.profileInfo {
    font-family: Inter, sans-serif;
  }
  .profile-trigger{
    display: none;
  }
  .profileTitle {
    color: #000;
    font-size: 36px;
    font-weight: 600;
    line-height: 0.1;
  }
  .mobileView{ display: none;}
  .signOutSection{display: none !important;}
  .profileDescription {
    color: #475467;
    font-size: 16px;
    font-weight: 400;
    margin-top: 15px;
    padding-left: 5px;
  }
  
  .profileDetails {
    display: flex;
    margin-top: 31px;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .userInfo {
    display: flex;
    gap: 23px;
    color: #475467;
    margin-top: 20px;
  }
  
  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #1f2937;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.1px;
  }
  
  .avatarPlaceholder {
    border-radius: 50%;
    background-color: #fff;
    width: 62px;
    height: 62px;
    border: 1px solid #1f2937;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contactInfo{
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 50px;
    margin-top: 20px;
  }
  .signOutIcon,.signOutSection{display: none;}
  
  @media (max-width: 991px) {
    .profileInfo {
      max-width: 100%;
      margin-top: 40px;
      margin-left: -20px;
    }
    
    .profileDetails {
      white-space: initial;
    }
    
    .bindingInfo {
      margin-top: 10px;
    }
    
    .bindingActions {
      padding-left: 20px;
    }
  }
  @media(max-width: 480px){
    .profileDescription{
      display: none;
    }
    .profileTitle{
      display: flex;
    }
    .signOutSection {
      display: flex;
      gap: 11px;
      color: rgba(0, 0, 0, 1);
      margin-left: 70px;
      margin-top: -20px;
    }
    .signOutIcon {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 24px;
      display: block;
    }
  }
  @media(max-width:375px){
    .signOutSection{
      display: flex;
      gap: 11px;
      color: rgba(0, 0, 0, 1);
      margin-left: 70px !important;
      margin-top: -20px;
    }
    .signOutIcon {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 24px;
      display: block;
    }
  }
  @media(max-width: 600px){
    .profileInfo{display: none;}
  .mobileView{display: block;}
  .modalContent h1 {
    font-size: 20px;
    display: flex;
    margin-top: -20px;
    margin-left: -25px;
  }
  .modalContent .sc {
    font-size: 20px;
    display: flex;
    margin-top: -20px;
    margin-left: -30px;
  }
  /* .signOutSection{
    display: block !important;
  } */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure it is above other content */
  }
  .accountlist p{font-size: 20px;}
  /* Modal content */
  .modalContent {
    background: white;
    padding: 40px;
    border-radius: 4px;
    position: relative;
    width: 100vw;
    height: 100vh !important;
  }
  b{
    color: gray;
    display: block;
  }
  .galleryContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px; /* Space between images */
    justify-content: space-around;
    margin-left: -20px;
  }
  
  .imageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align images and descriptions */
    position: relative;
  }
  .avatarPlaceholder{width: 55px; height: 55px; font-size: 15px;}
  .describe {
    display: block; /* Show descriptions in mobile view */
    font-size: 12px; /* Smaller font size for mobile */
    color: gray;
    padding: 5px;
   /* Space between image and description */
  }
  .video1{
    display: flex; 
    justify-content: space-between;
     width: 40vw; 
     margin-top: -12px;
    }
    .signOutSection {
      display: flex !important;
      gap: 11px;
      color: rgba(0, 0, 0, 1);
      margin-left: 200px !important;
      margin-top: -15px;
    }
  
    .signOutIcon {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 24px;
      display: block;
    }
    .accountdetails{
      margin-top: -40px;
    }
  
    .accountlist {
      display: grid;
      grid-template-columns: 90% 10%;
      /* margin-top: 20px; */
    }
  .demo b{
    font-size: 10px;
  }
    .order {
      display: grid;
      grid-template-columns: 90% 10%;
      margin-top: 20px;
    }
    .order p{margin-left: -10px;}
  
    .help {
      display: grid;
      grid-template-columns: 90% 10%;
      margin-top: 20px;
    }
    .filter{
      margin-left: 120px;
    }
    .demo {
      margin-left: -10px;
      margin-top: -10px;
    }
    .demo1{
      margin-top: -20px;
    }
    .Demo{
      font-size: 18px !important;
       width: 100vw;
      }
      .demo h2{
        font-size: 15px !important;
      }
    .minidescription {
      display: grid;
      grid-template-columns: 100% 1%;
      color: gray;
      margin-left: 30px !important;
      margin-top: 8px;
    }
  
    .authority {
      display: grid;
      grid-template-columns: 80% 20%;
      margin-top: 20px;
    }
  
    .Minidescription {
      display: grid;
      color: gray;
      margin-top: 8px;
    }
  
    .forwardicon {
      margin-left: 20px;
      color: green;
      font-weight: lighter;
    }
  
    .forwardIcon {
      margin-left: 20px;
      color: red;
      font-weight: lighter;
    }
  
    .policy p {
      margin-top: 40px;
      margin-bottom: 40px; /* Adjust this value to control the gap */
    }
    .privacy h1{margin-left: 6px;}
    
    /* Optional: To ensure the last <p> doesn't have extra space at the bottom */
    .policy p:last-child {
      margin-bottom: 0;
    }
    .description {
      color: gray;
    }
  select{
    width:20vw; 
    padding: 5px;
  }
  .gridimg{
    height: 12vh;
  }
  .demo b{
    color: gray;
    display: block;
  }
  .demo h2,.gridimg{
    font-size: 20px;
     margin-left: -10px;
  }
  h4{
    font-size: 14px; 
    margin-left: -20px !important;
  }
  .mini{
    display: flex; 
    justify-content: space-between;
    width: 40vw;
    margin-top: -10px;
  }
}
  @media(max-width : 320px){
  .profileInfo{display: none;}
  .mobileView{display: block;}
  .modalContent h1 {
    font-size: 20px;
    display: flex;
    margin-top: 20px;
    margin-left: -25px;
  }
  .modalContent .sc {
    font-size: 20px;
    display: flex;
    margin-top: 20px;
    margin-left: -30px;
  }
  .signOutSection{
    display: block !important;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure it is above other content */
  }
  .accountlist p{font-size: 16px; display: grid; grid-template-columns: 10% 90%;}

  .accountlist {
    gap: 10%;
  }

  /* Modal content */
  .modalContent {
    background: white;
    padding: 40px;
    border-radius: 4px;
    position: relative;
    width: 100vw;
    height: 100vh !important;
  }
  b{
    color: gray;
    display: block;
  }
  .galleryContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px; /* Space between images */
    justify-content: space-around;
    margin-left: -20px;
  }
  
  .imageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align images and descriptions */
    position: relative;
  }
  .avatarPlaceholder{width: 35px; height: 35px; font-size: 15px;}
  .describe {
    display: block; /* Show descriptions in mobile view */
    font-size: 12px; /* Smaller font size for mobile */
    color: gray;
    padding: 5px;
   /* Space between image and description */
  }
  .video1{
    display: flex; 
    justify-content: space-between;
     width: 40vw; 
     margin-top: -12px;
    }
    .signOutSection {
      display: flex !important;
      gap: 11px;
      color: rgba(0, 0, 0, 1);
      margin-left: 120px !important;
      margin-top: 5px;
    }
  
    .signOutIcon {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 24px;
      display: block;
    }
    .accountdetails{
      margin-top: -40px;
    }
  
    .accountlist {
      display: grid;
      grid-template-columns: 90% 10%;
      margin-top: 20px;
    }
  .demo b{
    font-size: 10px;
  }
    .order {
      width: 100vw;
      display: grid;
      grid-template-columns: 50% 50%;
      margin-top: 20px;
    }
    .order p{width: 800vw; margin-left: -10px;}
  
    .help {
      display: grid;
      grid-template-columns: 90% 10%;
      margin-top: 20px;
    }
    .filter{
      margin-left: 120px;
    }
    .demo {
      margin-left: -10px;
      margin-top: -10px;
    }
    .demo1{
      margin-top: -20px;
    }
    .Demo{
      font-size: 18px !important;
       width: 100vw;
      }
      .demo h2{
        font-size: 15px !important;
      }
    .minidescription {
      display: grid;
      grid-template-columns: 90% 10%;
      color: gray;
      margin-left: 30px !important;
      margin-top: 8px;
    }
  
    .authority {
      display: grid;
      grid-template-columns: 80% 20%;
      margin-top: 20px;
    }
  
    .Minidescription {
      display: grid;
      color: gray;
      margin-top: 8px;
    }
  
    .forwardicon {
      margin-left: 20px;
      color: green;
      font-weight: lighter;
    }
  
    .forwardIcon {
      margin-left: 20px;
      color: red;
      font-weight: lighter;
    }
  
    .policy p {
      margin-top: 40px;
      margin-bottom: 40px; /* Adjust this value to control the gap */
    }
    .privacy h1{margin-left: 6px;}
    
    /* Optional: To ensure the last <p> doesn't have extra space at the bottom */
    .policy p:last-child {
      margin-bottom: 0;
    }
    .description {
      color: gray;
    }
  select{
    width:20vw; 
    padding: 5px;
  }
  .gridimg{
    height: 12vh;
  }
  .demo b{
    color: gray;
    display: block;
  }
  .demo h2,.gridimg{
    font-size: 20px;
     margin-left: -10px;
    }
  h4{
    font-size: 14px; 
    margin-left: -20px !important;
  }
  .mini{
    display: flex; 
    justify-content: space-between;
    width: 40vw;
    margin-top: -10px;
  }
}