.calendarWrapper {
  margin-top: 20px;
}

.calendarControls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.prevWeek,
.nextWeek {
  background: none;
  border: none;
  color: #475467;
  cursor: pointer;
  margin-left: 10px;
}
.select {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background-color: #fff;
  font-size: 14px;
  color: #344054;
  width: 12%;
  margin-left: 10px;
}

.dateList {
  display: flex;
  gap: 20px;
  overflow-x: auto;
}

.dateItem {
  border-radius: 5px;
  background-color: #d9d9d9;
  width: 14%;
  height: 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.date {
  font-size: 20px;
  font-weight: 600;
}

.day {
  font-size: 14px;
  color: #696969;
}
.dateItem {
  cursor: pointer;
  padding: 0.5rem;
  margin: 0.25rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.selected {
  background-color: rgba(245, 244, 255, 1);
  color: black; /* Optional: Change text color for better contrast */
  font-family: Inter, sans-serif;
}

.selected .date,
.selected .day {
  color: darkblue /* Ensure text color is consistent */
}
@media (max-width: 991px) {
  .calendarControls{
   margin-right: 100px;
  }
   .dateItem {
     flex-wrap: nowrap;
   }
   .dateList{
    width: 120vw;
    gap: 40px;
    overflow-x:auto;
    margin-left: -30px;
   }
   
   .select{
     width: 50vw;
     margin-left: -30px;
     margin-top: 20px;
   }  
   .prevWeek, .nextWeek{
     margin-top: 20px;
   }  
 }
 @media(max-width:480px){
  .dateItem{
    width: 180vw;
  }
  .date{
    width: 30vw;
  }
 }
 @media(max-width: 320px){
  .calendarControls{
    margin-top: -25px;
  }
 }