/* Timeline.css */
.timeline-container {
  position: relative;
  margin: 20px 0;
  width: 100%;
  font-family: Arial, sans-serif;
  overflow-x: auto; /* Enable horizontal scrolling */
}

.timeline {
  position: relative;
  min-width: 100%;
  height: 85px;
  border: 2px solid #7f56d9;
  /* background: #e9ecef; */
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.file-marker {
  position: absolute;
  top: 10%;
  width: 4px;
  height: 70%;
  background-color: #ff9c7e;
  transform: translateX(-50%);
  cursor: pointer; /* Add cursor to indicate clickable items */
}
.cloudfile-marker {
  position: absolute;
  top: 10%;
  width: 4px;
  height: 70%;
  background-color: #7f56d9a6;
  transform: translateX(-50%);
  cursor: pointer; /* Add cursor to indicate clickable items */
}


.file-marker.highlighted {
  background-color: #7f56d9; /* Highlighted color */
}



.hour-marker {
  position: absolute;
  top: 10%;
  width: 1px;
  height: 70%;
  background-color: #5a5a5a;;
  transform: translateX(-50%);
}

.hour-marker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #5a5a5a;
  border-radius: 4px;
}

.hour-marker-label {
  position: absolute;
  top: 102%;
  left: 0;
  transform: translateX(-50%);
  font-size: 12px;
  color: #007bff;
}

.timeline-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 14px;
  color: #6c757d;
}

.timeline-label {
  flex: 1;
  text-align: center;
}

select {
  margin-bottom: 10px;
}

.hover-tooltip {
  position: absolute;
  top: -30px; /* Adjust this if the tooltip doesn't appear in the right place */
  background-color: #333;
  color: white;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  transform: translateX(-50%);
  white-space: nowrap;
  pointer-events: none;
  z-index: 10;
}
.hover-highlight {
  position: absolute;
  background: rgba(255, 0, 0, 0.5);
  z-index: 5;
  pointer-events: none;
}

